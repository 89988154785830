import {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {Portal} from '../../../external/components/Portal/Portal';

export const Detached: FC<PropsWithChildren<{level?: number}>> = ({children = undefined, level = undefined}) => {
  const ref = useRef<HTMLDivElement>(null);
  const sizeRef = useRef<Record<string, string | number>>({});
  const [isRefBound, setRefBound] = useState<boolean>(false);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (ref.current !== null) {
        sizeRef.current = {width: `${ref.current.offsetWidth}px`, height: `${ref.current.offsetHeight}px`};
        setRefBound(true);
      }
    });
  }, []);

  return (
    <div data-element="detached" ref={ref} style={{...(isRefBound ? sizeRef.current : {})}}>
      {isRefBound || <div className="w-fit h-fit invisible">{children}</div>}
      {isRefBound && (
        <Portal
          isOpen
          anchorEl={ref}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          backdrop={false}
          zIndex={level || 0}
        >
          {children}
        </Portal>
      )}
    </div>
  );
};
